import React from 'react'
import {useFormContext} from "react-hook-form";
import "./UiInputCheckbox.scss";

export default function UiInputCheckbox({ label, name, size, ...rest }: any){
    const formContext = useFormContext();
    const { register } = formContext;
    const LinkComponent = rest.linkComponent as JSX.Element;

    return (
        <div className={`ui-input-checkbox col-sm-${(size) ? size : 12}`}>
            <div className="form-item">
                <label className="form-label" htmlFor={name}>{label}{
                    LinkComponent &&
                    // @ts-ignore
                    <LinkComponent formContext={formContext} name={name}/>
                }</label>
                <label className="field-container" htmlFor={name}>
                    <div className="form-check">
                        <input type="checkbox" id={name} className="form-check-input" {...register(name)} {...rest} />
                    </div>
                </label>
            </div>
        </div>
    )
}