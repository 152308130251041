import React, {useContext} from 'react'
import UiInputText from "../components/Form/UiInputText";
import Form from "../components/Form/Form";
import {useForm, FormProvider} from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import UiInputSuggest from "../components/Form/UiInputSuggest";
import UiInputList from "../components/Form/UiInputList";
import AuthProvider from "../services/AuthProvider";
import UiInputNumberInteger from "../components/Form/UiInputNumberInteger";
import UiInputNumber from "../components/Form/UiInputNumber";

export default function ClienteForm(props: any){
    const { user } = useContext<any>(AuthProvider);

    const methods = useForm({
        defaultValues: props.data
    });
    const { handleSubmit } = methods;

    const generoList = [
        { id: 1, title: 'Masculino' },
        { id: 2, title: 'Feminino' },
    ];

    return (
        <Modal centered size="xl" show={true} animation={false} onHide={props.triggerClose}>
            <FormProvider {...methods} >
                <Form onSubmit={handleSubmit(props.triggerSubmit)} methods={methods}>
                    <Modal.Body>
                        <div className="row">
                            { user.acesso == 1 ? <>
                                <UiInputText mask={"uppercase"} size={6} labelSize={2} name="nome" label="Nome completo"/>
                                <UiInputSuggest size={3} router="vendedor" idField="id" titleField="nome" name="vendedor" label="Supervisor"/>
                            </> :
                                <UiInputText size={9} labelSize={2} name="nome" label="Nome completo"/>
                            }
                            <UiInputList size={3} name="genero" list={generoList} idField="id" titleField="title" label="Gênero" />
                            <UiInputText mask={"cpfCnpj"} size={3} labelSize={4} name="cpfCnpj" label="CPF/CNPJ"/>
                            <UiInputText mask={"date"} size={3} labelSize={6} name="dataNascimento" label="Data de Nascimento"/>
                            <UiInputText size={3} labelSize={2} name="rg" label="RG"/>
                            <UiInputText size={3} labelSize={5} name="rgOrgaoExpedidor" label="Orgão Expedidor"/>

                            <UiInputText mask={"uppercase"} size={3} labelSize={4} name="profissao" label="Profissão"/>
                            <UiInputText mask={"uppercase"} size={3} labelSize={4} name="estadoCivil" label="Estado Civil"/>

                            <UiInputText mask={"phone"} size={3} labelSize={4} name="telefone" label="Telefone"/>
                            <UiInputText mask={"lowercase"} size={3} labelSize={4} name="email" label="E-mail"/>

                            <UiInputText mask={"uppercase"} size={6} labelSize={2} name="endereco" label="Endereço"/>

                            <UiInputText size={3}
                                         labelSize={6}
                                         name="numero"
                                         label="Número"
                                         showDisableCheckbox={true}
                                         onDisableSetValue={`S/N`}
                                         disableButtonLabel={`S/N`}
                            />

                            <UiInputText mask={"uppercase"} size={3} labelSize={4} name="complemento" label="Complemento"/>
                            <UiInputText mask={"uppercase"} size={4} labelSize={3} name="bairro" label="Bairro"/>
                            <UiInputText mask={"uppercase"} size={4} labelSize={6} name="cidade" label="Cidade"/>
                            <UiInputText mask={"uppercase"} size={2} labelSize={4} name="estado" label="Estado"/>
                            <UiInputText mask={"cep"} size={2} labelSize={3} name="cep" label="CEP"/>

                            <UiInputText size={6} labelSize={2} name="localizacao" label="Localização"/>
                            <UiInputSuggest size={6} router="cliente" idField="id" titleField="nome" name="pj" label="Cliente PJ"/>

                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.triggerClose}>Fechar</Button>
                        <Button variant="primary" type="submit">Salvar</Button>
                    </Modal.Footer>
                </Form>
            </FormProvider>
        </Modal>
    )
}