import React, {useEffect} from 'react'
import {useFormContext} from "react-hook-form";
import cn from "classnames";
import {
    normalizeCepNumber,
    normalizeCpfCnpjNumber, normalizeCpfNumber,
    normalizeHumanDate,
    normalizePhoneNumber, normalizeToLowerCase,
    normalizeToUpperCase
} from "../../util/Masks";

export default function UiInputText({ label, name, size, mask, linkComponent, prependElements, appendElements, showDisableCheckbox, onDisableSetValue, disableButtonLabel, asTable, ...rest }: any){
    const formContext = useFormContext();
    const { register } = formContext;
    const LinkComponent = linkComponent as JSX.Element;

    const value = formContext.watch(name);
    const isDisabled = formContext.watch(`${name}_disabled`);
    useEffect(() => {
        if(onDisableSetValue) {
            if (isDisabled) {
                formContext.setValue(name, onDisableSetValue);
            }else if(value == onDisableSetValue){
                formContext.setValue(name, '');
            }
        }
    }, [ isDisabled ]);

    useEffect(() => {
        if(mask){
            switch(mask){
                case 'phone':
                    formContext.setValue(name, normalizePhoneNumber(value))
                    break;
                case 'date':
                    formContext.setValue(name, normalizeHumanDate(value))
                    break;
                case 'cep':
                    formContext.setValue(name, normalizeCepNumber(value))
                    break;
                case 'cpfCnpj':
                    formContext.setValue(name, normalizeCpfCnpjNumber(value))
                    break;
                case 'cpf':
                    formContext.setValue(name, normalizeCpfNumber(value))
                    break;
                case 'uppercase':
                    formContext.setValue(name, normalizeToUpperCase(value))
                    break;
                case 'lowercase':
                    formContext.setValue(name, normalizeToLowerCase(value))
                    break;
            }
        }
    }, [ value ]);


    return (
        <div className={cn(`col-sm-${(size) ? size : 12}`, `ui-input-text`, {
            'with-append': showDisableCheckbox
        })}>
            <div className="form-item">
                { !asTable &&
                    <label className="form-label">{label}{
                        LinkComponent &&
                        // @ts-ignore
                        <LinkComponent formContext={formContext} name={name}/>
                    }
                    </label>
                }
                <div className="field-container">
                    { prependElements && prependElements(`${name}`, formContext) }
                    <input disabled={isDisabled} type="text" className="form-control" {...register(name)} {...rest} />
                    { showDisableCheckbox &&
                        <label className="form-append">
                            <input type="checkbox" className="form-check-input" {...register(`${name}_disabled`)} />
                            { disableButtonLabel ? disableButtonLabel : `N/A` }
                        </label>
                    }
                    { appendElements && appendElements(`${name}`, formContext) }
                </div>
            </div>
        </div>
    )
}