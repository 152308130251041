import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {AgGridReact} from "ag-grid-react";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './AbstractGrid.scss';

import {get, post} from "../../services/ApiService";
import {ColumnApi, GridApi, GridReadyEvent, IDatasource, IGetRowsParams, ModelUpdatedEvent} from "ag-grid-community";
import Overlay from "../Overlay";
import {Link, MousePointer, PenTool, Plus, RefreshCw, X, Tablet} from "react-feather";
import {Util} from "../../util/Util";
//import {useLocation} from "react-router";
import {getEnumColumnParams} from "../../util/EnumFilter";
import {Toast} from "primereact/toast";

export interface Filter {
    name: string,
    op: string
    v: string,
    v2?: string,
}

const numberValueParser = (params: any) => {
    return Number(params.newValue);
};

const formatMoeda = (number: number) => {
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    return Util.moeda(number)
};

const cellRendererList = {
    Moeda: (params: any) => {
        return formatMoeda(params.value);
    },
    Percent: (params: any) => {
        return <span className={(params.value > 0) ? 'up' : 'down'}>{params.value}%</span>;
    },
    UpDown: (params: any) => {
        return <span className={(params.value > 0) ? 'up' : 'down'}>{params.value}</span>;
    },
    Link: (params: any) => {
        return <a href={params.value} target="_blank"><Link size={16} /></a>;
    },
    Image: (params: any) => {
        return <a href={params.value} target="_blank"><img style={{height: '100%'}} src={params.value} /></a>;
    },
    Tag: (params: any) => {
        if(params.value) {
            const valueList = JSON.parse(params.value);
            const filterOptions = params.column.colDef?.filterParams?.filterOptions;
            if (filterOptions && filterOptions.length) {
                const filterMap = filterOptions.reduce((map: any, obj: any) => {
                    map[obj.displayKey] = obj.displayName;
                    return map;
                }, {});
                return <span>{valueList && valueList.map((item: any) => <div key={item}
                                                                             className="tag">{filterMap[item]}</div>)}</span>;
            }
        }

        return <span></span>;
    }
}

const getRowClass = (params: any) => {
    if(params?.data?.cont_situacao) {
        return 'situacao-' + params.data.cont_situacao;
    }
};

export default function NewAbstractGrid(props: { router: string, selected?: any, selectedIndex?: any, initialFilter?: any, formComponent?: any, onSelect?: any, openMenu?: any, extraData?: any }) {
    //const { state } = useLocation();
    const openMenu = props.openMenu;
    const state = {};
    const { onInitOpenData } = props as any || { onInitOpenData: null };
    const {router, selected} = props;
    const FormComponent = props.formComponent;

    const gridRef = useRef<any>();
    const toast = useRef(null);

    const [gridApi, setGridApi] = useState<GridApi>();
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();

    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [action, setAction] = useState('add');
    const [data, setData] = useState(selected);
    const [rowData, setRowData] = useState();

    const [actionList, setActionList] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [initialFilterList, setInitialFilterList] = useState([]);
    const defaultColDef = useMemo(() => ({
        sortable: true,
        //flex: 1,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true
    }), []);

    const resetGridParams = () => {
        setColumnDefs([]);
    }

    const openNewGridTab = (props?: any) => {
        openMenu({name: 'Alteração Kits', icon: <Tablet/>, component: React.memo(NewAbstractGrid), props: {router: 'kitlog'}});
    }

    const tratarGridParams = (data: any) => {
        let columnDefs: any = [];
        if(data.actionList){
            let actionList: any = [];
            Object.keys(data.actionList).map((actionItemIdx: any) => {
                const actionItem = data.actionList[actionItemIdx];
                let actionDef = actionItem;

                let trigger: (props?: any) => void = () => { return null; };
                switch(actionItem.trigger.handler){
                    case 'openNewGridTab':
                        trigger = openNewGridTab;
                        break
                    default:
                        trigger = alert;
                        break;
                }
                if(trigger) {
                    actionDef.onClick = () => {
                        trigger(actionItem.trigger.params);
                    }
                }

                switch(actionItem.icon){
                    case 'Plus': actionDef.icon = Plus; break
                    case 'Tablet': actionDef.icon = Tablet; break
                    default:    actionDef.icon = null; break
                }

                actionList.push(actionDef);
            });
            setActionList(actionList);
        }
        data.fieldList.map((item: any) => {
            let columnDef: any = {
                field: item.name,
                headerName: item.header,
                //type: item.type,
                hide: !item.visible,
            };

            if(item.width){
                columnDef.width = item.width;
            }
            if(item.minWidth){
                columnDef.minWidth = item.minWidth;
            }
            if(item.defaultFlex){
                columnDef.flex = item.defaultFlex;
            }
            if(item.filter){
                columnDef.filter = item.filter;
                if(columnDef.filter == 'agDateColumnFilter'){
                    columnDef.filterParams = {
                        comparator: (filterDate: any, cellValue: any) => {
                            if (cellValue == null) return -1;

                            // Converta o valor da célula para Date
                            const cellDate = new Date(cellValue);

                            // Comparação para ordenar o filtro
                            if (cellDate < filterDate) return -1;
                            if (cellDate > filterDate) return 1;
                            return 0;
                        },
                            browserDatePicker: true,
                    }
                    // columnDef.valueFormatter = (params: any) => {
                    //     if (!params.value) return '';
                    //     const date = new Date(params.value);
                    //     const day = date.getDate().toString().padStart(2, '0');
                    //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    //     const year = date.getFullYear();
                    //     return `${day}/${month}/${year}`;
                    // }
                }
            }
            if(item.filterList){
                columnDef = { ...columnDef, ...getEnumColumnParams(item.filterList) }
                /*columnDef.filter = 'agSetColumnFilter';
                columnDef.filterParams = {
                    values: (params: any) => {
                        params.success({ a: 'abc', b: 'def' });
                    }
                }*/
            }
            if(item.cellClass){
                columnDef.cellClass = item.cellClass;
            }
            if(item.formatter){
                // @ts-ignore
                columnDef.cellRenderer = cellRendererList[item.formatter];
                // @ts-ignore
                //columnDef.valueParser = valueParserList[item.formatter][0];
                // @ts-ignore
                //columnDef.valueFormatter = valueParserList[item.formatter][1];
            }

            columnDefs.push(columnDef);
        });

        data.filterList.map((filterItem: any) => {
            columnDefs = columnDefs.map((columnItem: any) => {
                let newColumnItem = columnItem;
                if (columnItem.field == filterItem.name) {
                    if(!newColumnItem.filter) {
                        newColumnItem.filter = 'agTextColumnFilter';
                    }
                }
                return newColumnItem;
            });
        });

        if(data.initialFilterList){
            //console.log('Initial from web: ', data.initialFilterList);
            setInitialFilterList(data.initialFilterList);
            /*let actualFilterModel = gridApi.getFilterModel();
            let newFilterModel = {
                ...data.initialFilterList,
                ...actualFilterModel
            }
            gridApi.setFilterModel(newFilterModel);*/
        }

        setColumnDefs(columnDefs);
    }

    useEffect(()=>{
        const dataSource = createDataSource();
        gridApi?.setDatasource(dataSource);
    }, [gridApi])

    const loadGridParams = React.useCallback(() => {
        //setGridParams(null);
        return get(`/?route=${props.router}/grid&action=actionGetGridParams`)
            .then((response: any) => {
                if (response.status == 200) {
                    const data = response.data;

                    if (data && data.fieldList?.length > 0) {
                        tratarGridParams(data);
                    } else {
                        resetGridParams();
                        alert(data);
                    }
                } else {
                    alert('Falha ao carregar esta página 1');
                }
            }).catch((err) => {
                alert('Falha ao carregar esta página 2' + err);
            })
    }, [router]);

    useEffect(() => {
        loadGridParams();
        if(onInitOpenData){
            openAdd(onInitOpenData)
        }
    }, []);

    const buttonListener = useCallback(e => {
        if (gridRef.current?.api) {
            gridRef.current.api.deselectAll();
        }
    }, []);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        const dataSource = createDataSource();
        gridApi?.setDatasource(dataSource);
    }

    const onModelUpdated = (event: ModelUpdatedEvent) => {
        event.api.forEachNode(node => {
            if (node.data?.selected) {
                node.setSelected(true);
            }
        });

        if(props.initialFilter || initialFilterList){
            let actualFilterModel = event.api.getFilterModel();
            let newFilterModel = {
                ...initialFilterList,
                ...props.initialFilter,
                ...actualFilterModel
            }

            console.log(newFilterModel);

            event.api.setFilterModel(newFilterModel);
        }
    }

    const getFilters = () => {
        let filters: Array<Filter> = [];
        if (gridApi?.getFilterModel()) {
            let filterModelData = gridApi?.getFilterModel();
            for (let filterName in filterModelData) {
                let filterModel = filterModelData[filterName];
                let val = filterModel.filter;
                let val2 = null;
                let operador = '=';
                //console.log(filterModel);
                switch (filterModel.type) {
                    case 'contains':
                        operador = 'like';
                        break;
                    case 'blank':
                        operador = 'filled';
                        val = '2';
                        break;
                    case 'notBlank':
                        operador = 'filled';
                        val = '1';
                        break;
                    case 'lessThan':
                        operador = 'lt';
                        break;
                    case 'lessThanOrEqual':
                        operador = 'lte';
                        break;
                    case 'greaterThan':
                        operador = 'gt';
                        break;
                    case 'greaterThanOrEqual':
                        operador = 'gte';
                        break;
                    case 'notContains':
                        operador = 'notlike';
                        break;
                    case 'equals':
                        operador = 'equal';
                        break;
                    case 'notEqual':
                        operador = 'neq';
                        break;
                    case 'startWith':
                        operador = 'inicialike';
                        break;
                    case 'endsWith':
                        operador = 'terminalike';
                        break;
                    case 'inRange':
                        operador = 'between';
                        break;
                    case 'in':
                        operador = 'contains';
                        break;
                    default:
                        val = filterModel.type;
                        break;
                }

                if (filterModel.dateFrom) {
                    val = filterModel.dateFrom;
                }

                if (filterModel.dateTo) {
                    val2 = filterModel.dateTo;
                }

                if (filterModel.filterTo) {
                    val2 = filterModel.filterTo;
                }

                let param: Filter = {
                    "name": filterName,
                    "v": val,
                    "v2": val2,
                    "op": operador
                };

                filters.push(param);
            }
        }
        return filters;
    }

    const createDataSource = () => {
        const dataSource: IDatasource = {
            getRows: (params: IGetRowsParams) => {
                gridApi?.showLoadingOverlay();
                let page = gridApi?.paginationGetCurrentPage();
                if (page) {
                    page++;
                }
                let sortData = [];
                for (let row in params.sortModel) {
                    let data = params.sortModel[row];
                    sortData.push({name: data.colId, dir: data.sort});
                }
                let filters = getFilters();
                let limit = gridApi?.paginationGetPageSize() ?? 10;
                let requestParams: any = {
                    filter: filters,
                    page,
                    limit: limit,
                    start: params.startRow,
                    sort: sortData
                };

                const url = `/?route=${props.router}/grid&action=list&skip=${requestParams.start}&limit=${requestParams.limit}&filter=${JSON.stringify(requestParams.filter)}&sort=${JSON.stringify(requestParams.sort)}&extraData=${JSON.stringify(props.extraData)}`;
                get(url)
                    .then((response: any) => {
                        if (response.status == 200) {
                            const data = response.data;

                            if(props.selected){
                                data.rows.map((item: any) => {
                                    item.selected = (item.id === props.selected.id);
                                    return item;
                                });
                            }

                            gridApi?.hideOverlay();
                            params.successCallback(
                                data.rows,
                                data.total
                            );
                        } else {
                            alert('Falha ao carregar esta pagina (#1)');
                        }
                    }).catch((err) => {
                    alert('Falha ao carregar esta página (#2)' + err);
                });
            }
        };

        return dataSource;
    }

    const onSelectionChanged = ()=>{
        if(gridApi){
            let localSelectedRows = gridApi.getSelectedRows();
            setData(localSelectedRows[0]);
        }
    }

    const triggerOnSelect = () => {
        if(data && props.onSelect){
            props.onSelect(data);
        }
    }

    const openAdd = (data: any = null) => {
        setData(data);
        setAction('add');
        setShowForm(true);
    }

    const openEdit = () => {
        setLoading(true);
        get(`/?route=${props.router}/form&action=edit&rows=${encodeURIComponent(JSON.stringify([data]))}`)
            .then((response: any) => {
                if (response.status == 200){
                    if(response.data.success) {
                        setData(response.data.payload);
                        setAction('edit');
                        setShowForm(true);
                    } else {
                        return Promise.reject(response.data);
                    }
                } else {
                    return Promise.reject('Falha ao carregar');
                }
            })
            .catch((err: any) => {
                toastAlert({
                    severity: 'error',
                    summary: 'Erro!',
                    detail: 'Erro no carregamento dos dados: ' + err.toString(),
                    life: 10000
                });
            })
            .finally(() => setLoading(false));
    }

    const triggerRefreshGrid = () => {
        const currentPage = gridApi?.paginationGetCurrentPage();
        gridApi?.setDatasource(createDataSource());
    }

    const openDelete = () => {
        if(window.confirm('Você tem certeza?')) {
            setLoading(true);
            post(`/?route=${props.router}/form&action=delete`, { dados: [data] })
                .then((response: any) => {
                    if (response.status == 200) {
                        if (response.data.success) {
                            setShowForm(false);
                            triggerRefreshGrid();
                            toastAlert({
                                severity: 'success',
                                summary: 'Sucesso!',
                                detail: response.data.message,
                                life: 3000
                            });
                        }else{
                            toastAlert({
                                severity: 'error',
                                summary: 'Erro!',
                                detail: response.data.message,
                                life: 3000
                            });
                        }
                    } else {
                        return Promise.reject('Falha ao carregar');
                    }
                })
                .catch((err) => {
                    alert('Falha ao carregar os dados do cliente3');
                })
                .finally(() => setLoading(false));
        }
    }

    const triggerSubmit = (data: any) => {
        setLoading(true);
        post(`/?route=${props.router}/form&action=${action}`, {dados: data})
            .then((response: any) => {
                if (response.status == 200) {
                    if (response.data.success) {
                        setShowForm(false);
                        triggerRefreshGrid();
                        toastAlert({
                            severity: 'success',
                            summary: 'Sucesso!',
                            detail: response.data.message,
                            life: 3000
                        });
                    }else{
                        return Promise.reject(response.data.message);
                    }
                } else {
                    return Promise.reject('Falha ao carregar');
                }
            })
            .catch((err: any) => {
                toastAlert({
                    severity: 'error',
                    summary: 'Erro!',
                    detail: err,
                    life: 3000
                });
            })
            .finally(() => setLoading(false))
    }

    const triggerSubmitAsNew = (data: any) => {
        //console.log(data);
        setLoading(true);
        post(`/?route=${props.router}/form&action=add`, {dados: data})
            .then((response: any) => {
                if (response.status == 200) {
                    if (response.data.success) {
                        setShowForm(false);
                        triggerRefreshGrid();
                        toastAlert({
                            severity: 'success',
                            summary: 'Sucesso!',
                            detail: response.data.message,
                            life: 3000
                        });
                    }else{
                        return Promise.reject(response.data.message);
                    }
                } else {
                    return Promise.reject('Falha ao carregar');
                }
            })
            .catch((err: any) => {
                toastAlert({
                    severity: 'error',
                    summary: 'Erro!',
                    detail: err,
                    life: 3000
                });
            })
            .finally(() => setLoading(false))
    }

    const triggerClose = () => {
        setShowForm(false);
    }

    const toastAlert = (payload: any) => {
        // @ts-ignore
        toast && toast.current && toast.current.show(payload);
    }

    return (
        <>
            <Overlay show={loading} />

            <Toast ref={toast} />

            <div className="action-grid-container">
                <div className="on-left">
                    { props.onSelect && <button onClick={triggerOnSelect} disabled={!(data != null)} className="btn btn-sm btn-secondary"><MousePointer /> Selecionar</button> }
                    { FormComponent && <button onClick={() => openAdd()} className="btn btn-sm btn-success"><Plus /> Adicionar</button> }
                    { FormComponent && <button onClick={openEdit} disabled={!(data != null)} className="btn btn-sm btn-primary"><PenTool /> Alterar registro</button>}

                    {
                        actionList.map((actionItem: any, actionItemIdx) => {
                            return <button
                                key={actionItemIdx}
                                onClick={actionItem.onClick}
                                className={actionItem.class}
                            >
                                { actionItem.icon && <actionItem.icon /> }
                                {actionItem.title}
                            </button>
                        })
                    }

                    <button onClick={triggerRefreshGrid} className="btn btn-sm btn-default align-self-end"><RefreshCw /> Atualizar</button>
                </div>
                <div className="on-right">
                    { FormComponent && <button onClick={openDelete} disabled={!(data != null)} className="btn btn-sm btn-danger"><X /> Remover</button>}
                </div>
            </div>

            { FormComponent && showForm && <FormComponent triggerClose={triggerClose} triggerSubmitAsNew={triggerSubmitAsNew} triggerSubmit={triggerSubmit} data={data} parentProps={props} /> }

            <div className="ag-theme-alpine" style={{width: '100%', flex: 1 }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}

                    pagination={true}
                    paginationPageSize={20}
                    cacheBlockSize={20}

                    rowHeight={25}
                    //paginationAutoPageSize={true}

                    getRowClass={getRowClass}

                    rowModelType={'infinite'}
                    //cacheBlockSize={20}
                    onGridReady={onGridReady}
                    onSelectionChanged={onSelectionChanged}
                    onModelUpdated={onModelUpdated}
                    onCellDoubleClicked={triggerOnSelect}
                    rowSelection={'single'}
                    localeText={{
                        // for number filter and text filter
                        notEqual: 'Diferente',
                        blank: 'Em branco',
                        notBlank: 'Não em branco',
                        filterOoo: 'Filtro...',
                        lessThan: 'Menor',
                        greaterThan: 'Maior',
                        lessThanOrEqual: 'Menor ou igual',
                        greaterThanOrEqual: 'Maior ou igual',
                        inRange: 'No intervalo',
                        inRangeStart: 'De',
                        inRangeEnd: 'Até',
                        equals: 'Igual',
                        contains: 'Contém',
                        notContains: 'Não Contém',
                        startsWith: 'Inicia com',
                        endsWith: 'Termina com',
                        // filter conditions
                        andCondition: 'e',
                        orCondition: 'ou',
                        //rodapé
                        page: 'página',
                        more: '',
                        to: 'até',
                        of: 'de',
                        next: 'próxima',
                        last: 'última',
                        first: 'primeira',
                        previous: 'anterior',
                        loadingOoo: 'Carregando Dados...',
                    }}
                />
            </div>
        </>
    );

}
